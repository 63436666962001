.map {
  height: 64vh; 
  margin-left: auto;
  margin-right: auto;
}

.smallmap {
  height: 39vh; 
  margin-left: auto;
  margin-right: auto;
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
    opacity: 0.85;
}

h2, h3, h4 {
    margin: 0;
    color: #333333;
}

.grey {
    color: #777;
}

.legend {
    line-height: 18px;
    color: #555;
    z-index: 10 !important; 
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.legend-label {
    margin-left: 28px;
    margin-bottom: 5px;
}

.linkbutton {
    margin: 0;
    font-size: inherit;
    font: inherit;
    background: none;
    border: none;
    color: #5874a3; 
}
 
.linkbutton:hover{
    background: none;
    text-decoration: underline;
    cursor: pointer;
}

.control-panel {
  max-width: 100%;
  padding: 10px;
}

.control-section {
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 11;
}
  
.modal.is-active {
    display: flex;
}
  
.modal-content {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    max-width: 90%;
    width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
}
  
.modal-window {
    position: relative; 
    border: 1px solid #333;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
  
.modal-close {
    position: absolute; 
    top: 10px;
    right: 10px;
    color: #333;
    font-size: 2em;
    font-weight: bold;
    text-decoration: none;
}
  
.modal-close:hover {
    color: #000;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .info {
        font: 12px/14px Arial, Helvetica, sans-serif;
    }
  
    .legend {
        line-height: 16px;
    }

    .legend i {
        width: 14px;
        height: 14px;
    }
  
    .legend-label {
        margin-left: 22px;
        margin-bottom: 4px;
    }
}
